// ---Dependencies
import React from "react";
// ---UI Dependencies
import { Box } from "@mui/material";
// ---Components
import { SimpleTextF } from "./SimpleTextF/SimpleTextF";
import { RoleInput } from "./RoleInput/RoleInput";
// ---Types
import { IUpdateUserFormProps } from "../../../models/Users";
import { ICatalogs } from "../config/useCatalogs";

interface Props extends IUpdateUserFormProps {
  catalogs: ICatalogs;
}
const UpdateForm: React.FC<Props> = ({ formik, catalogs }) => {
  // -----------------------RENDER
  return (
    <Box
      sx={{
        maxWidth: "500px",
        marginTop:10,
        "& .MuiTextField-root": { m: 1, width: {
          xs:'100%',
          lg:"500px"
        } },
        flex: 1,
      }}
    >
      <SimpleTextF label="First name*" formik={formik} valueName="firstName" />
      <SimpleTextF label="Last name*" formik={formik} valueName="lastName" />
      <SimpleTextF
        label="Phone number*"
        formik={formik}
        valueName="phoneNumber"
      />
      <RoleInput formik={formik} />
    </Box>
  );
};

export default UpdateForm;
