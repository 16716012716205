export type StatusType = "Active" | "Acknowledged" | "Resolved" | null;
export const getStatusColor = (status: StatusType): string => {
  switch (status) {
    case "Active":
      return "#f56565"; 
    case "Acknowledged":
      return "#ecc94b"; 
    case "Resolved":
      return "#10b981"; 
    default:
      return ""; 
  }
};