import { Typography } from "@mui/material";
import {
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { CustomSort } from "../../../components/DataGrid";
import { getStatusColor, StatusType } from "../../../utils/helpers/style";

export const AlertInProgressColumns = (actionsColumns: any) => {

  return [
    {
      headerName: "Student Name",
      field: "studentName",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Location",
      field: "location",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      valueGetter: (params: any) => params.row?.location?.name ?? "-",
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Created By",
      field: "alertCreatedBy",
      flex: 1,
      hideSortIcons: true,
      valueGetter: (params: any) =>
        params.row.alertCreatedBy.firstName +
        " " +
        params.row.alertCreatedBy.lastName,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Event Type",
      field: "emergencyType",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      valueGetter: (params: any) => params.row?.emergencyType?.name ?? "-",

      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const emergencyType = params.value;

        return (
          <div style={{ justifyContent: "center" }}>
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "6px 12px",
                borderRadius: "12px",
              }}
            >
              {emergencyType}
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "alertStatus",
      flex: 1,
      hideSortIcons: true,
      minWidth: 200,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description || "-"}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const status: StatusType = params.value as StatusType;
        const statusColor = getStatusColor(status);

        return (
          <Typography
            fontSize="0.9rem"
            textAlign="center"
            style={{
              backgroundColor: statusColor,
              color: "white",
              padding: "6px 12px",
              borderRadius: "12px",
            }}
          >
            {status ? status : "-"}
          </Typography>
        );
      },
    },

    {
      headerName: "Comments",
      field: "comments",
      flex: 1,
      hideSortIcons: true,
      minWidth: 200,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description || "-"}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography fontSize="0.9rem" textAlign="center">
            {params.value ? params.value : "-"}
          </Typography>
        );
      },
    },
    actionsColumns,
  ];
};
